import { lowerCase, startCase } from 'lodash'
import pluralize from 'pluralize'

/**
 * Capitalize each token in a string.
 * @param {*} string String to format.
 * @returns Capitalized string.
 */
export const capitalize = (string) => {
    return startCase(string)
}

/**
 * Singularize a string.
 * @param {string} string String to format.
 * @returns Singularized string.
 */
export const singular = (string) => {
    return pluralize.singular(lowerCase(string))
}

/**
 * Pluralize a string.
 * @param {string} string String to format.
 * @returns Pluralized string.
 */
export const plural = (string) => {
    return pluralize.plural(lowerCase(string))
}