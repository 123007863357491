import Repository from './repository'

import { ACTIONS } from '../../constants'
import { TYPE as AGENT_TYPE } from '../../models/dto/Agent'
import { TYPE as MORTGAGE_DEAL_TYPE } from '../../models/dto/MortgageDeal'
import { URL as AGENT_URL } from './agent.service'
import { URL as MORTGAGE_DEAL_URL } from './mortgage.deal.service'

// URLs associated with uploading CSV files.
const URL = {
  'CSV': 'upload/csv',
  [AGENT_TYPE.AGENT]: AGENT_URL[AGENT_TYPE.AGENT],
  [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL]: MORTGAGE_DEAL_URL[MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL],
}

class CSVService {

  getURL (type, action) {
    return `${URL.CSV}/${URL[type]}/${action}/`
  }

  uploadFile (type, action, formData, callback) {
    this.validate(type, action)
    return Repository.postWithFile(this.getURL(type, action), formData, callback,)
  }

  validate (type, action) {
    switch (type) {
      case AGENT_TYPE.AGENT:
      case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL:
        break
      default:
        throw TypeError(`Type, "${type}", does not have a CSV end-point implemented.`)
    }

    switch (action) {
      case ACTIONS.ADD:
      case ACTIONS.UPDATE:
        break
      default:
        throw TypeError(`Action, "${action}", is not supported.`)
    }
  }

}

const csvService = new CSVService()
export default csvService