import { isNil } from "lodash"
import { ACTIONS } from "../constants"

export const COLUMN_TYPE_ALPHA = {
  type: 'alpha',
  validator: v => v && /^[-'\.\s\p{Letter}']+$/uig.test(v) || 'Expected an alphabetic value.',
}

export const COLUMN_TYPE_ALPHA_NUMERIC = {
  type: 'alpha_numeric',
  validator: v => v && /^[-'\.\s\p{Letter}0-9]+$/uig.test(v) || 'Expected an alpha-numeric value.',
}

export const COLUMN_TYPE_DATE = {
  type: 'date',
  validator: v => v && /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/.test(v) || 'Expected a date value formatted as "YYYY-MM-DD".',
}

export const COLUMN_TYPE_EMAIL = {
  type: 'email',
  validator: v => v && /^\S+@\S+\.\S+$/.test(v) || 'Expected an email address.',
}

export const COLUMN_TYPE_NUMERIC = {
  type: 'numeric',
  validator: v => v && /^[0-9]+(\.[0-9]+)?$/.test(v) || 'Expected a numeric value.',
}

export const COLUMN_TYPE_WILDCARD = {
  type: '*',
  validator: v => !!v || 'Expected a value.',
}

 /**
 * Config used to define column-generation in CSV files.
 */
export default class FileColumnConfig {
  /**
   * Create config for a column by type.
   * @param {string} name Name of column header that will appear in CSV file.
   * @param {string} text Name of the column header formatted for display.
   * @param {string} type Data type contained in this column.
   */
  constructor (name, text, type) {
    this.name = name
    this.text = text
    this.type = type.type
    this.validate = type.validator
    this.enabled = true
    this.required = true
    this._requires = []
    // This flag can be manually overwritten when creating a validated field to not validate its data in an uploaded file.
    // Useful for circumventing rigid validation for value that can be null (user-friendly) that will be handled in the
    // backend with default values.
    this._ignoreFileValidation = false
  }

  /**
   * Set this column to not be displayed in GUI components, nor present in the file unless required by another field.
   * @returns this FileColumnConfig instance.
   */
  disable() {
    this.enabled = false
    return this
  }

  /**
   * Set the flag that indicates if this column will have its file validation ignored.
   * @returns this FileColumnConfig instance.
   */
  ignoreFileValidation() {
    this._ignoreFileValidation = true
    return this
  }

  /**
   * Flag that indicates if this column can be added.
   * @returns True when this column can be included in a ADD-mode CSV file, false otherwise.
   */
  isAddColumn() {
    return isNil(this._mode) || this._mode !== ACTIONS.UPDATE
  }

  /**
   * Flag that indicates if this column can have its data validated. 
   * @returns 
   */
  isIgnoringFileValidation() {
    return this._ignoreFileValidation
  }

  /**
   * Indicate that this column should use the valid rules of another table and column. 
   * @param {*} table Table to get rules from.
   * @param {*} column Column to get rules from. If column is null, this column's name is used.
   * @returns this FileColumnConfig instance.
   */
  useValidRules(table, column) {
     if (isNil(column)) {
       column = this.name
     }
    this.rulesTarget = { 'table': table, 'column': column, }
    return this
  }

  /**
   * Set which mode (ADD, UPDATE) this column is permitted to be included in the file.
   * @param {string} mode ADD or UPDATE mode
   * @returns this FileColumnConfig instance. 
   */
  mode(mode) {
    this._mode = mode
    return this
  }

  /**
   * Set this column to not require data in its fields.
   * @returns this FileColumnConfig instance.
   */
  optional() {
    this.required = false
    return this
  }

  /**
   * Adds a column to the file that this column is dependent upon. Usually a primary key for the table this column is part of.
   * @param {*} required Dependent column string or array of dependent column strings.
   * @returns this FileColumnConfig instance.
   */
  requires(required) {
    if (Array.isArray(required)) {
      this._requires = [...this._requires, ...required,]
    } else {
      this._requires.push(required)
    }
    return this
  }
}